/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react'
import Modal from '../components/app/Modal'

const ModalContext = React.createContext()

const ModalProvider = props => {
  const [modal, setModal] = useState()
  const unSetModal = useCallback(() => {
    setModal()
  }, [setModal])

  let title = ''
  let content = modal

  if (typeof modal === 'object') {
    if ('title' in modal) {
      title = modal.title
    }
    if ('content' in modal) {
      content = modal.content
    }
  }

  return (
    <ModalContext.Provider value={{ unSetModal, setModal }} {...props}>
      {modal && <Modal modal={content} title={title} unSetModal={unSetModal} />}
      {props.children}
    </ModalContext.Provider>
  )
}

const useModal = () => {
  const context = React.useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a UserProvider')
  }

  return context
}

export { ModalProvider, useModal }

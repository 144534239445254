import { useContext, useEffect, useState } from 'react'
import { domoscioAccess } from '../api/domoscio_access'
import { Learning } from '../contexts/learning'
import I18n from '../locales/I18n'
import { lxpHubUtils } from '@domoscio/domoscio-sdk-js'

const first = (array: any[]) => (array && array.length > 0 ? array[0] : undefined)

export function useNextAction(lps: any) {
  // State init
  const [nextAction, setNextAction] = useState<any>({})
  const [nextActionLoading, setNextActionLoading] = useState<any>(true)

  // Context init
  const { learningSessions, objectiveStudents } = useContext(Learning.State)

  async function getNextAction() {
    const action = lps?.next_action

    // prettier-ignore
    const ls = learningSessions.find(ls => ls.objective_id === action?.objective?.id && ls?.type === 'LearningSessionAdaptive')

    const os = objectiveStudents.find(os => os.objective_id === ls?.objective_id)

    if (action?.learning_session?.type === 'LearningSessionPositioning') {
      const nextActionProps = {
        themeName: action?.objective?.name,
        title: I18n.t('base.positioning_myself'),
        tags: [
          {
            type: 'quiz',
            value: I18n.t('base.positioning')
          }
        ],
        url: ls ? `/learning_sessions/${ls?.id}/adaptive` : null,
        id: os?.objective?.id,
        subInfos: {}
      }
      const isAutoPoz = os?.objective?.adaptive_options?.positioning_type === 'SelfPosSelection'

      // Don't display positionning time for Autopoz
      const options =
        os && !isAutoPoz
          ? lxpHubUtils.getObjectiveOptions(os?.objective)
          : { countdown: undefined, avgTime: undefined, timer: undefined }

      if (options?.timer) {
        nextActionProps.tags.push({ type: 'time', value: options.countdown })
      } else if (options?.avgTime) {
        nextActionProps.tags.push({ type: 'time', value: options.avgTime })
      }
      const skillCount = action?.objective?.knowledge_nodes_count

      nextActionProps.subInfos = [
        {
          type: 'quiz',
          value: I18n.t('base.positioning')
        },
        {
          type: 'star',
          value:
            skillCount +
            ' ' +
            I18n.t('positioning_result.skills.title', { count: skillCount || 1 }).toLowerCase()
        }
      ]

      setNextAction(nextActionProps)
    } else if (action?.learning_session?.type === 'LearningSessionAdaptive') {
      // Fetch associated learning session to get next_recommendation
      const learningSession = await domoscioAccess.getLearningSessions({
        id: action?.learning_session?.id
      })

      const learningSessionAdaptive = first(learningSession)
      const recommendation = learningSessionAdaptive?.next_recommendation

      if (recommendation) {
        const image = recommendation?.content.image_url
          ? `https://${recommendation?.content.image_url}`
          : null
        const tags = [] as any[]

        if (recommendation.content?.external_learning_type) {
          const type = recommendation.content?.external_learning_type
          tags.push({ type: 'doc', value: I18n.t(`lxp_hub.adaptive_path.content.${type}`) })
        }
        if (recommendation.content?.internal_learning_type) {
          const type = recommendation.content?.internal_learning_type
          tags.push({ type: 'doc', value: I18n.t(`lxp_hub.adaptive_path.content.${type}`) })
        }

        if (recommendation.content.estimated_time) {
          tags.push({
            type: 'time',
            value: lxpHubUtils.msToTime(recommendation.content.estimated_time)
          })
        }

        const nextActionProps = {
          themeName: recommendation?.objective.name,
          title: recommendation?.content.name,
          tags,
          imageUrl: image,
          status: 'resume',
          url: ls ? `/learning_sessions/${ls?.id}/adaptive` : null,
          id: os?.objective?.id,
          subInfos: {}
        }

        nextActionProps.subInfos = [
          {
            type: 'star',
            value: recommendation.knowledge_node.name
          },
          {
            type: 'doc',
            value:
              I18n.t('base.level') +
              ' ' +
              lxpHubUtils
                .transformCogValue(
                  recommendation.content.difficulty_level,
                  os?.objective.nb_levels || 0
                )
                ?.toFixed()
          }
        ]

        setNextAction(nextActionProps)
      }
    }
  }

  useEffect(() => {
    // if next action on lps is {}, null or undefined don't need to wait for next action
    const isNextActionExist = Object.keys(lps?.next_action || {}).length !== 0
    if (!isNextActionExist) {
      setNextActionLoading(false)
    } else if (learningSessions.length > 0) {
      getNextAction().then(res => setNextActionLoading(false))
    }
  }, [learningSessions])

  return { nextAction, nextActionLoading }
}

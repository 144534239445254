import React from 'react'
import { Client } from '../../contexts/parameters'
import { IoHelpCircleOutline } from 'react-icons/io5'
import I18n from '../../locales/I18n'
import './Footer.scss'
import { deleteTags } from '../../screens/LearningSession/Positionning/utils'

const Footer = () => {
  const client = React.useContext(Client.State)

  return client?.parameters?.mqb_display_support ? (
    <div className='supportLine'>
      <div>
        {' '}
        {I18n.t('base.support')}{' '}
        <a href={`mailto:${deleteTags(client?.parameters?.mqb_support_email)}`}>
          {deleteTags(client?.parameters?.mqb_support_email)}
        </a>
        <IoHelpCircleOutline />
      </div>
    </div>
  ) : (
    <div></div>
  )
}

export default Footer

/**
 * @file Display Not Found 404 error screen
 */

import * as React from 'react'
import { useDomToolkit } from '../../styles/dom-toolkit'
import { ReactComponent as NotFound } from '../../assets/errors/not_found.svg'
import { DomButton } from '@domoscio/domoscio-ui'
import './errors.css'
import I18n from '../../locales/I18n'

export interface NotFoundScreenProps {
  fill: string
  support?: string
}

/**
 * Renders the 404 Screen
 *
 * @category Screens
 */
function NotFoundScreen({
  fill = '#005eb8',
  support = 'support@domoscio.com'
}: NotFoundScreenProps) {
  const classes: any = useDomToolkit()

  return (
    <div className={`errorContainer ${classes.domTextPrimary} ${classes.domTextBold}`}>
      <div className={'errorBackground'}>404</div>
      <div className={'errorContent'}>
        <div className={'notFoundContent'}>
          {I18n.t('errors.not_found.content')}
          <div className={`${classes.domFlexPerfect} ${classes.domMargin}`}>
            <DomButton
              variant='secondary'
              onClick={() => {
                window.location.href = 'mailto:' + support
              }}
            >
              {I18n.t('errors.support')}
            </DomButton>
          </div>
        </div>
        <div className={'notFoundContainer'}>
          <div className={'notFoundTitle'}>{I18n.t('errors.not_found.image_text')}</div>
          <NotFound fill={fill} />
        </div>
      </div>
    </div>
  )
}

export default NotFoundScreen

import React, { ReactNode, useEffect, useState } from 'react'
import ReactJoyride from 'react-joyride'
import { OnboardingTooltip } from '@domoscio/domoscio-ui'
import { User } from '../../contexts/parameters'
import I18n from '../../locales/I18n'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { domoscioAccess } from '../../api/domoscio_access'

type Props = {
  children: ReactNode
  isDisplayStep2: boolean
  isOts: boolean
}
const initSteps = (isOts?: boolean) => {
  return [1, 2, 3].map(elem => ({
    title: I18n.t(`tour.learning_program_show.title_step_${elem}`),
    target: `.listing_theme_step_${elem}${elem === 3 ? '  .card' : ''}`,
    content: I18n.t(`tour.learning_program_show.step_${elem}`),
    disableBeacon: true,
    placement: elem === 1 ? ('bottom' as any) : ('top' as any),
    spotlightPadding: isOts && elem === 3 ? 15 : 10
  }))
}

const LearningProgramShow = ({ children, isDisplayStep2, isOts }: Props) => {
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)
  const [runTour, setRunTour] = useState(false)

  useEffect(() => {
    if (!user.onboarding?.lxp_hub?.learningProgramShow) {
      setRunTour(true)
    }
  }, [])

  const handleCallbackJoyRide = (data: any) => {
    const { action, status } = data

    // Send finished tour to server
    if (status === 'finished' || action === 'reset') {
      const newOnboarding = domUtils.updateTour(user.onboarding, 'lxp_hub', {
        learningProgramShow: true
      })
      if (!user.onboarding?.lxp_hub?.learningProgramShow) {
        userDispatch({ ...user, onboarding: newOnboarding })
        domoscioAccess.updateUserParameter({ onboarding: newOnboarding })
      } else {
        userDispatch({ ...user, replayTour: { ...user.replayTour, learningProgramShow: false } })
      }
    }
  }

  const createSteps = () => {
    let allSteps = initSteps(isOts)
    if (!isDisplayStep2) {
      allSteps.splice(1, 1)
      allSteps = allSteps.map((elem, i) => ({
        ...elem,
        title:
          I18n.t('tour.learning_program_show.title_step_1').split(' ')[0] +
          ' ' +
          `${i + 1}/${allSteps.length}`
      }))
    }

    return allSteps
  }

  return (
    <div>
      <ReactJoyride
        floaterProps={{ disableAnimation: true }}
        tooltipComponent={OnboardingTooltip}
        steps={createSteps()}
        continuous={true}
        run={user.replayTour.learningProgramShow || runTour}
        styles={{
          options: {
            zIndex: 10000
          },
          spotlight: {
            borderRadius: 20
          }
        }}
        callback={arg => handleCallbackJoyRide(arg)}
      />
      {children}
    </div>
  )
}

export default LearningProgramShow

/* eslint-disable @typescript-eslint/no-use-before-define */
/**
 * @file Manages the methods of the HomeNav component.
 * @author Matthieu Mugnier
 */

import React from 'react'
import { IoSettingsOutline, IoInformationCircleOutline } from 'react-icons/io5'
import { Link, useLocation } from 'react-router-dom'
import { User } from '../../contexts/parameters'
import './HomeNav.scss'

function HomeNav() {
  const user = React.useContext(User.State)

  const userDispatch = React.useContext(User.Dispatch)
  const location = useLocation()
  const isIndex = location.pathname === '/'

  const handleClickHelp = () => {
    if (isIndex) {
      userDispatch({ ...user, replayTour: { ...user.replayTour, learningProgramIndex: true } })
    } else {
      userDispatch({ ...user, replayTour: { ...user.replayTour, learningProgramShow: true } })
    }
  }

  return (
    <header className='HomeNav'>
      <div className='rightCol'>
        <span className='navIcon' onClick={() => handleClickHelp()}>
          <IoInformationCircleOutline />
        </span>
        <Link
          to={{ pathname: '/settings' }}
          state={{ from: location.pathname }}
          className='navIcon settings'
        >
          <IoSettingsOutline />
        </Link>
      </div>
    </header>
  )
}
export default HomeNav

import React, { useRef } from 'react'
import { FiEyeOff, FiEye } from 'react-icons/fi'
import { MdOutlineArrowBack } from 'react-icons/md'
import { SimpleProgress } from '@domoscio/domoscio-ui'
import { prepareExperienceStats } from '../../utils'
import StatAndTitle from './StatAndTitle'
import { useContainerDimensions } from '../../../../hooks/useContainerSize'
import { LearningProgramStudent } from '../../../../types/learninProgram'
import './DashboardHeroSection.scss'
import I18n from '../../../../locales/I18n'
import { useNavigate } from 'react-router-dom'

type Props = {
  lps: LearningProgramStudent
  primaryColor?: string
  setIsDisplayIntro: (arg: boolean) => void
  isDisplayIntro: boolean
  isDisplayBackButton: boolean
}

const DashboardHero = ({
  lps,
  primaryColor,
  setIsDisplayIntro,
  isDisplayIntro,
  isDisplayBackButton
}: Props) => {
  const heroSectionRef = useRef<any>()
  const navigate = useNavigate()

  const { width: heroWidth } = useContainerDimensions(heroSectionRef)

  const themeCount = lps.nbr_knowledge_graphs || 0
  const skillCount = lps.nbr_knowledge_nodes || 0
  const imageUrl = lps.image_url
  const intro = lps?.introduction

  return (
    <div
      className={'heroSection listing_theme_step_1'}
      style={{ background: primaryColor ? '#fff' : '' }}
    >
      <div className='upper'>
        {isDisplayBackButton && (
          <div className='backBtn'>
            <span
              onClick={() => navigate('/')}
              style={{ color: primaryColor || '#fff', borderColor: primaryColor || '#fff' }}
              className='arrowWrapper'
            >
              <MdOutlineArrowBack />
            </span>
          </div>
        )}
        <div style={{ width: imageUrl ? '70%' : '100%' }} className='colRight'>
          <div style={{ color: primaryColor ? '#474747' : '#fff' }} className='line1 expName'>
            {lps?.name}
          </div>
          <div style={{ marginLeft: lps.total_time ? -20 : -47 }} className='line2'>
            {prepareExperienceStats(themeCount, skillCount, primaryColor, lps.total_time).map(
              (elem, index) => (
                <StatAndTitle
                  key={index}
                  {...elem}
                  index={lps.total_time ? index : index + 1}
                  iconColor={primaryColor || '#fff'}
                />
              )
            )}
          </div>
        </div>
      </div>
      <div ref={heroSectionRef} className='bottom'>
        <div className={`line3 ${!primaryColor ? 'otsBarValue' : ''}`}>
          <SimpleProgress
            value={lps.hub_progression}
            labelType={'percentage'}
            labelPosition={'topRight'}
            barColor={primaryColor || '#263C66'}
            trailColor={primaryColor ? 'rgba(25, 25, 87, 0.2)' : 'rgba(255, 255, 255, 0.5)'}
            size={{ width: heroWidth - 85, height: '3px' }}
          />
        </div>
        {intro && (
          <div className='line4'>
            <div
              onClick={() => setIsDisplayIntro(!isDisplayIntro)}
              className='introIconwrapper'
              style={{ color: primaryColor ? '#A4AAB6' : '#f7f7fb' }}
            >
              <span>
                {!isDisplayIntro ? (
                  <FiEyeOff color={primaryColor ? '#A4AAB6' : '#f7f7fb'} />
                ) : (
                  <FiEye color={primaryColor ? '#A4AAB6' : '#f7f7fb'} />
                )}
              </span>
              <span>{I18n.t('base.intro')}</span>
            </div>
          </div>
        )}
      </div>
      {imageUrl && (
        <div className='topRightImg'>
          <img src={'https://' + imageUrl} />
        </div>
      )}
      {primaryColor && (
        <div
          style={{ backgroundColor: primaryColor }}
          className='lineHorizontal
'
        ></div>
      )}
    </div>
  )
}

export default DashboardHero

import React, { useState } from 'react'
import I18n from '../../../../locales/I18n'
import ButtonOts from '../../../../components/ButtonOts/ButtonOts'
import { MdClose } from 'react-icons/md'
import './DownloadModal.scss'
import { Client, User } from '../../../../contexts/parameters'
import { pdf } from '@react-pdf/renderer'
import CertificateRenderer from '../PDFRenderers/CertificateRenderer/CertificateRenderer'
import { saveAs } from 'file-saver'
import { domoscioAccess } from '../../../../api/domoscio_access'

type Props = {
  experience: string
  finishedAt: string
  isOts: boolean
  setIsDisplayModal: (arg: boolean) => void
  skillList: Array<string>
  themeList: Array<string>
}

const DownloadModal = ({
  isOts,
  setIsDisplayModal,
  skillList,
  themeList,
  ...otherProps
}: Props) => {
  const user = React.useContext(User.State)
  const client = React.useContext(Client.State)
  const [userInput, setUserInput] = useState({
    firstName: user.firstname || '',
    lastName: user.lastname || ''
  })

  const primaryColor = isOts ? '#6C51F6' : client.mqb_primary_color

  const handleInputChange = (index: number, e: any) => {
    if (index === 0) {
      setUserInput(state => ({ ...state, lastName: e.target.value }))
    } else if (index === 1) {
      setUserInput(state => ({ ...state, firstName: e.target.value }))
    }
  }

  const getLineCount = (skillList: string[]) => {
    function getTextWidth(text: string, font: string) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      if (context) {
        context.font = font
        return context.measureText(text).width
      } else {
        return 0
      }
    }
    return skillList.map(skill => (getTextWidth(skill, '15px') > 193 ? 2 : 1))
  }

  const prepareSkillNameColumns = (skillList: any[]) => {
    const list = [[], [], []] as Array<Array<number>>
    let count = 0
    let col = 0
    const skillNameLineCount = getLineCount(skillList)

    skillList.forEach((element, index) => {
      if (skillNameLineCount[index] + count <= 8) {
        list[col].push(skillNameLineCount[index])
        count += skillNameLineCount[index]
      } else if (col < 2) {
        count = skillNameLineCount[index]
        col += 1
        list[col].push(skillNameLineCount[index])
      }
    })
    return list
  }

  /* if there are to many skill name and it is impossible
  to display on a single page,  show theme names instead of skill
  names
  */
  const isOverflowSkillList = () =>
    prepareSkillNameColumns(skillList)
      .flat()
      .reduce((partialSum, a) => partialSum + a, 0) <
    getLineCount(skillList).reduce((partialSum, a) => partialSum + a, 0)

  const skillCount = skillList.length
  const themeCount = themeList.length

  const generateCertificate = async () => {
    // kpi logs (feature tracking)
    domoscioAccess.postKpiLog('StudentCertificate')

    const blob = await pdf(
      <CertificateRenderer
        name={`${userInput.firstName} ${userInput.lastName}`}
        id={user.uid}
        isOts={isOts}
        primaryColor={primaryColor}
        skillCount={isOverflowSkillList() ? themeCount : skillCount}
        isSkillBased={!isOverflowSkillList()}
        skillNameLineCount={
          isOverflowSkillList() ? getLineCount(themeList) : getLineCount(skillList)
        }
        skillList={isOverflowSkillList() ? themeList : skillList}
        {...otherProps}
      />
    ).toBlob()
    saveAs(blob, I18n.t('skill_assessment.certificate.doc_name'))
  }

  const handleClick = () => {
    if (userInput.firstName && userInput.lastName) {
      generateCertificate()
    }
  }

  const isClicable = userInput.firstName && userInput.lastName

  const inputs = [I18n.t('base.last_name'), I18n.t('base.first_name')]
  return (
    <div className='dwnloadModal'>
      <div className='modalMain'>
        <div className='header'>
          {I18n.t('learning_program_show.experince_finished.download_my_results')}
        </div>
        {inputs.map((input, index) => (
          <div key={index} className='inputGroup'>
            <div>
              <div className='inputTitle'>{input}</div>
            </div>
            <input
              className={`${!userInput[index === 1 ? 'firstName' : 'lastName'] ? 'redBorder' : ''}`}
              onChange={e => handleInputChange(index, e)}
              type='text'
              value={index === 0 ? userInput.lastName : userInput.firstName}
            />
          </div>
        ))}

        <div className='bottomElem'>
          <span className={`${isClicable ? 'clicable' : 'nonClicable'}`}>
            <ButtonOts
              variant={'first'}
              isOts={isOts}
              disabled={!isClicable}
              handleClick={handleClick}
            >
              {I18n.t('base.download')}
            </ButtonOts>
          </span>
        </div>
        <span className='modalCloseIcon'>
          <MdClose onClick={() => setIsDisplayModal(false)} />
        </span>
      </div>
    </div>
  )
}

export default DownloadModal

/**
 * @file Manages the Reactjs Container and init methods.
 */

import './core/App.css'

import React from 'react'
import { Toaster } from 'react-hot-toast'
import Navigation from './navigation/Navigation'
import Initializers from './core/Initializers'
import ClientHeightObserver from './core/ClientHeightObserver'
import ErrorWrapper from './components/ErrorBoundary/ErrorFallback'
import { Parameters } from './contexts/parameters'
import { LearningProvider } from './contexts/learning'
import { useLocation } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

// Create a client
const queryClient = new QueryClient()

function App() {
  const location = useLocation()
  const isShow = location.pathname.split('/').includes('learning_programs')
  return (
    <ErrorWrapper>
      <ClientHeightObserver>
        <Parameters>
          <LearningProvider>
            <Initializers>
              <QueryClientProvider client={queryClient}>
                <div className={`bodyWrapper ${isShow ? 'lightBg' : ''}`}>
                  <div className={'App domScroll'}>
                    <Navigation />
                    <Toaster />
                  </div>
                </div>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  panelProps={{ style: { height: 400, width: 1000 } }}
                  position='bottom-left'
                />
              </QueryClientProvider>
            </Initializers>
          </LearningProvider>
        </Parameters>
      </ClientHeightObserver>
    </ErrorWrapper>
  )
}

export default App

/* eslint-disable no-unused-vars */
import { lxpHubTypes } from '@domoscio/domoscio-sdk-js'
import I18n from '../../locales/I18n'
import { LearningProgramStudent } from '../../types/learninProgram'
export type Status = 'doing' | 'toDo' | 'done' | 'disabled'
type IconType = 'doc' | 'quiz' | 'star' | 'grid'
type FilterValues = 'toDo' | 'done' | 'doing' | 'pending'
type StatusText = { id: FilterValues; value: string }
export type LearningProgramStudentDatas = {
  id: number
  cardStatus: Status
  statusText: StatusText
  subInfos: Array<{ type: IconType; text: string }>
  progress: number
  title: string
  lpId: number
  isOts: boolean
  imageUrl: string | undefined
  isClickable: boolean
}
export const isExpPending = (
  lps: LearningProgramStudent,
  status: string | number,
  objectiveStudents: lxpHubTypes.ObjectiveStudent[]
) => {
  return (
    lps?.next_action &&
    Object.keys(lps.next_action).length === 0 &&
    status !== 'done' &&
    objectiveStudents.every(o => Object.keys(o.next_action).length === 0)
  )
}

export const isExternalAssessment = (objective: any) =>
  objective?.objective?.adaptive_options?.positioning_type === 'Assessment'

// Return string status by progress integer
export const getStatusByProgress = (progression: number) => {
  if (progression === 0) {
    return 'to_do'
  } else if (progression === 100) {
    return 'done'
  } else {
    return 'doing'
  }
}

export const prepareExperianceCardDatas = (
  learningProgramStudent: LearningProgramStudent,
  ObjectiveStudents: lxpHubTypes.ObjectiveStudent[]
) => {
  const data = {} as LearningProgramStudentDatas
  data.id = learningProgramStudent.id
  data.progress = learningProgramStudent.hub_progression
  data.imageUrl = learningProgramStudent.image_url
  data.title = learningProgramStudent.name
  data.subInfos = [
    learningProgramStudent?.nbr_knowledge_nodes || 0,
    learningProgramStudent?.nbr_knowledge_graphs || 0
  ].map((elem, index) => ({
    type: index === 0 ? 'star' : 'grid',
    text:
      elem +
      '  ' +
      (index === 0
        ? I18n.t('base.skill', {
            count: learningProgramStudent?.nbr_knowledge_nodes || 0
          }).toLowerCase()
        : I18n.t('base.theme', {
            count: learningProgramStudent?.nbr_knowledge_graphs || 0
          }).toLowerCase())
  }))

  const isPending = isExpPending(
    learningProgramStudent,
    learningProgramStudent?.hub_status,
    ObjectiveStudents
  )

  let cardStatus = 'toDo' as Status
  if (learningProgramStudent.hub_status === 'done') {
    cardStatus = 'done'
  } else if (isPending) {
    cardStatus = 'disabled'
  } else if (learningProgramStudent.hub_status === 'doing') {
    cardStatus = 'doing'
  }
  data.cardStatus = cardStatus
  const statusText = {
    id: isPending ? 'pending' : (cardStatus as FilterValues),
    value: I18n.t('learning_program_index.status.' + cardStatus)
  }
  data.statusText = statusText
  data.isOts = learningProgramStudent.is_ots
  return data
}
export const createFilterInputs = (
  os: lxpHubTypes.ObjectiveStudent[],
  lps?: LearningProgramStudent[]
) => {
  const status = ['all', 'toDo', 'doing', 'done']
  const options = [
    I18n.t('filter.all'),
    I18n.t('filter.learning_programs.todo'),
    I18n.t('filter.learning_programs.doing'),
    I18n.t('filter.learning_programs.done')
  ]

  let isDisplayPendingForExpScreen = false
  lps?.forEach(elem => {
    const objectiveList = os.filter(o => o.learning_program_id === elem.learning_program_id)
    if (isExpPending(elem, elem?.hub_status, objectiveList)) {
      isDisplayPendingForExpScreen = true
    }
  })
  if (isDisplayPendingForExpScreen) {
    options.push(I18n.t('filter.learning_programs.pending'))
    status.push('pending')
  }

  return options.map((elem, index) => ({ value: elem.toString(), id: status[index] }))
}

// Sort by ObjectiveStudent array by ISO 8601 created_at
export const byObjectiveCreatedAt = (
  a: lxpHubTypes.ObjectiveStudent,
  b: lxpHubTypes.ObjectiveStudent
) => {
  if (a.objective.created_at < b.objective.created_at) return -1
  if (a.objective.created_at > b.objective.created_at) return 1
  return 0
}

// Sort by ObjectiveStudent array by ISO 8601 created_at
export const byObjectiveId = (a: lxpHubTypes.ObjectiveStudent, b: lxpHubTypes.ObjectiveStudent) => {
  if (a.objective.id < b.objective.id) return -1
  if (a.objective.id > b.objective.id) return 1
  return 0
}

// Sort by ObjectiveStudent array by objective status
export const byObjectiveStatus = (a: any, b: any) => {
  if (a.lsAId === 0 && b?.lsAId !== 0) return 1
  if (a?.lsAId !== 0 && b?.lsAId === 0) return -1
  return 0
}

export const prepareExperienceStats = (themes: any, skills: any, isPrimary: any, time: any) => {
  const hours = Math.floor(time / (1000 * 60 * 60)) + 1
  const list = [
    {
      value: hours,
      title: I18n.t('base.hours_or_more', { count: hours }),
      index: 1
    },
    { value: themes, title: I18n.t('base.theme', { count: themes }), index: 1 },
    { value: skills, title: I18n.t('base.skill', { count: skills }), index: 1 }
  ]

  !time && list.shift()

  return list
}

export const updateIntro = (onboarding, lpsId) => {
  const newInroList = [...(onboarding?.lxp_hub?.learning_program_introduction || [])]
  newInroList.push(lpsId)
  const newOnboarding = {
    ...onboarding,
    lxp_hub: {
      ...(onboarding.lxp_hub || {}),
      learning_program_introduction: newInroList
    }
  }
  return newOnboarding
}

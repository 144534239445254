import React, { createContext, useContext, useState } from 'react'

type Animations = {
  experienceScreen?: boolean
  themeScreen?: boolean
  tsrScreen?: boolean
  experiencScreen?: boolean
}

const defaultAnimations = {
  experienceScreen: false,
  themeScreen: false,
  tsrScreen: false,
  experiencScreen: false
} as Animations

const defaultAnimationContext = {
  animations: defaultAnimations,
  setAnimations: (arg: typeof defaultAnimations) => {}
}

const AnimationContext = createContext(defaultAnimationContext)

type Aux = {
  children: React.ReactNode
}

const AnimationContextProvider = ({ children }: Aux) => {
  const [animations, setAnimations] = useState(defaultAnimations)

  const value = { animations, setAnimations }

  return <AnimationContext.Provider value={value}>{children}</AnimationContext.Provider>
}

type Screens = 'experienceScreen' | 'themeScreen' | 'tsrScreen' | 'experiencScreen'
const useAnimations = (animation: Screens) => {
  const { animations, setAnimations } = useContext(AnimationContext)
  if (animations === undefined) {
    throw new Error('useAnimations must be used within a UserProvider')
  }
  const updatedAnimations = { ...animations }
  updatedAnimations[animation] = true
  setAnimations(updatedAnimations)
}

export { AnimationContextProvider, useAnimations }

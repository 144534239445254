import { useState, useContext, useEffect } from 'react'
import {
  fetchStudentLearningDatas,
  getKnowledgeNodes,
  getLearningPrograms,
  getLearningProgramStudents,
  getObjectives
} from './fetchLearningDatas'
import { User, Session } from '../contexts/parameters'
import { Learning } from '../contexts/learning'
import { assignLearningProgramInfos } from '../utils/format/learningPrograms'
import { assignObjectivesInfos } from '../utils/format/objectives'
import { KnowledgeNode, LearningProgramStudent } from '../types/learninProgram'
import { lxpHubTypes } from '@domoscio/domoscio-sdk-js'

interface Data {
  [key: string]: Array<any>
}

export function useLearningDatas(init: boolean = false, parentRoute: string | null = null) {
  const user = useContext(User.State)
  const session = useContext(Session.State)
  const [learning, setLearning] = [useContext(Learning.State), useContext(Learning.Dispatch)]
  const waitfullyLoading = parentRoute !== null

  const [datas, setDatas] = useState<{
    loading: boolean
    error?: Error
    data?: Data
  }>({
    loading: true,
    data: learning,
    error: undefined
  })

  function fetchLearningDatas(studentUid: string) {
    const learningDatas: Data = { ...datas.data }
    setDatas({ loading: true })
    const learningProgramStudentIds = session?.options?.lxp?.learning_program_student_id

    // 1 - Get student scoped learning_program_students
    getLearningProgramStudents(studentUid, learningProgramStudentIds)
      .then((learningProgramStudents: LearningProgramStudent[]) => {
        // 2 - Get student scoped learning_programs from LP uids
        getLearningPrograms(learningProgramStudents).then((learningPrograms: any) => {
          learningDatas.learningPrograms = learningPrograms
          learningDatas.learningProgramStudents = assignLearningProgramInfos(
            learningPrograms,
            learningProgramStudents
          )
          // Stop loading
          setLearning(learningDatas, {})
          setDatas({ loading: waitfullyLoading, data: learningDatas })

          // 3 - Get student scoped objectives from LP ids
          getObjectives(learningPrograms).then((objectives: lxpHubTypes.Objective[]) => {
            learningDatas.objectives = objectives

            // 4 - Get objectives related knowledge_nodes
            getKnowledgeNodes(objectives).then((knowledgeNodes: KnowledgeNode[]) => {
              learningDatas.knowledgeNodes = knowledgeNodes
              const objectiveIds = objectives.map(objective => objective.id)
              // 5 - Get last learningDatas infos
              fetchStudentLearningDatas(studentUid, objectiveIds).then(
                (studentLearningDatas: any) => {
                  // Finaly : set complete data & finish loading
                  studentLearningDatas.objectiveStudents = assignObjectivesInfos(
                    objectives,
                    studentLearningDatas.objectiveStudents,
                    studentLearningDatas.learningSessions
                  )
                  const datas = { ...learningDatas, ...studentLearningDatas }
                  setLearning(datas, {})
                  setDatas({ loading: false, data: datas })
                }
              )
            })
          })
        })
      })
      .catch((error: Error) => {
        setDatas({ loading: false, error })
      })
  }

  useEffect(() => {
    if (init && user?.uid) {
      fetchLearningDatas(user.uid)
    }
  }, [])

  return { ...datas, user, fetchLearningDatas }
}

// 🔗 Ressources : https://codesandbox.io/s/usefetch-39ql4

import React, { useContext, useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { NoResult, ThemeCard, ListingCards } from '@domoscio/domoscio-ui'
import { lxpHubUtils } from '@domoscio/domoscio-sdk-js'
import { useDomToolkit } from '../../styles/dom-toolkit'
import { Client, Session } from '../../contexts/parameters'
import { Learning } from '../../contexts/learning'
import I18n from '../../locales/I18n'
import { createFilterInputs, prepareExperianceCardDatas } from './utils'

import HomeNav from '../../components/navigation/HomeNav'
import * as Tours from '../../components/Tours'

import './LearningProgram.scss'
import Footer from '../../components/Footer/Footer'

const LearningProgramIndex = () => {
  // Hooks
  const navigate = useNavigate()
  const classes: any = useDomToolkit()

  // Contexts
  const session = useContext(Session.State)
  const client = useContext(Client.State)
  const primaryColor = client.mqb_primary_color

  const lpsContext = session?.options?.lxp?.learning_program_student_id
  const { learningProgramStudents, objectiveStudents } = useContext(Learning.State)

  const [filters, setFilters] = useState({
    search: '',
    status: 'all'
  })

  // Redirect to LearningPrograms.Show screen if lps context found
  useEffect(() => {
    if (lpsContext) {
      navigate(`/learning_programs/${lpsContext}`)
    }
  }, [lpsContext])

  const inputSelectOptions = useMemo(
    () => createFilterInputs(objectiveStudents, learningProgramStudents),
    []
  )

  const getLpsObjectives = (lpId: number) => {
    return objectiveStudents.filter(os => os.learning_program_id === lpId)
  }

  const experienceCardDatas = learningProgramStudents.map(lps =>
    prepareExperianceCardDatas(lps, getLpsObjectives(lps.learning_program_id))
  )
  const filteredList = experienceCardDatas.filter(elem => {
    if (filters.status === 'all') {
      return lxpHubUtils.isTextMatches(filters.search, elem.title)
    } else {
      return (
        elem.statusText.id === filters.status &&
        lxpHubUtils.isTextMatches(filters.search, elem.title)
      )
    }
  })
  return (
    <Tours.LearningProgramIndex>
      <div className='LearningProgramIndex'>
        {/* Heading */}
        <div className='heading'>
          {/* Title */}
          <div className='title'>{I18n.t('learning_program_index.title')}</div>
          {/* Filters */}
          <div className={`filtersAndIcon ${classes.domFlex1} ${classes.domFlexRight}`}>
            <ListingCards
              inputSelectOptions={inputSelectOptions}
              filters={filters}
              onChange={setFilters}
            />
            <HomeNav />
          </div>
        </div>
        <div>
          {filteredList.length === 0 ? (
            <NoResult />
          ) : (
            <div className='expList'>
              {filteredList.map((lps, index) => {
                return (
                  <NavLink key={lps.id} to={`/learning_programs/${lps.id}`}>
                    <div className={`cardContainer ${index === 0 ? 'step1' : ''} `}>
                      <ThemeCard
                        cardStatus={lps.cardStatus}
                        statusText={lps.statusText}
                        primaryColor={primaryColor}
                        progress={lps.progress}
                        title={lps.title}
                        timeInfo={null}
                        subInfos={lps.subInfos}
                        isOts={lps.isOts}
                        isHighLighted={false}
                        imageUrl={lps.imageUrl}
                      />
                    </div>
                  </NavLink>
                )
              })}
              {[...Array(3 - (filteredList.length % 3))].map((_, index) => {
                return <div key={index} className='emptyCard'></div>
              })}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </Tours.LearningProgramIndex>
  )
}

export default LearningProgramIndex

import React, { useContext, useState } from 'react'
import I18n from '../../../../locales/I18n'
import conrats from '../../../../assets/imgs/conrats.png'
import './EndOfExpCard.scss'
import ButtonOts from '../../../../components/ButtonOts/ButtonOts'
import { useNavigate } from 'react-router-dom'
import DownloadModal from '../../../SkillAssessment/subComponents/DownloadModal/DownloadModal'
import { LearningProgramStudent } from '../../../../types/learninProgram'
import { Learning } from '../../../../contexts/learning'
import { getSkillNames } from '../../../SkillAssessment/utils'
import { domUtils } from '@domoscio/domoscio-sdk-js'

type Props = {
  primaryColor?: string
  isOts: boolean
  lps: LearningProgramStudent
  lspObjectives: any[]
}

const EndOfExpCard = ({ primaryColor = ' #6355FF', isOts, lps, lspObjectives }: Props) => {
  const [isDisplayModal, setIsDisplayModal] = useState(false)
  const { knowledgeNodes } = useContext(Learning.State)

  const finishedAt = domUtils.convertDate(lps?.updated_at)

  const navigate = useNavigate()
  const handleClickAsessementPage = () => {
    navigate('skill_assessment', { state: { isOts } })
  }

  return (
    <>
      <div className='nextActionTitle'>{I18n.t('base.end_experience')}</div>
      <div className='endOfExpCard'>
        <div className='colLeft'>
          <div
            style={{
              color: primaryColor
            }}
            className='text1'
          >
            {I18n.t('learning_program_show.experince_finished.message1')}
          </div>
          <div className='text2'>{I18n.t('learning_program_show.experince_finished.message2')}</div>

          <div className='buttons'>
            <ButtonOts variant={'first'} isOts={isOts} handleClick={handleClickAsessementPage}>
              {I18n.t('learning_program_show.experince_finished.see_my_results')}
            </ButtonOts>
            {lps.has_certificate && (
              <ButtonOts
                handleClick={() => setIsDisplayModal(true)}
                variant={'second'}
                isOts={isOts}
              >
                {I18n.t('learning_program_show.experince_finished.download_my_results')}
              </ButtonOts>
            )}
          </div>
        </div>
        <div className='colRight'>
          <img src={conrats} alt='conrats' />
        </div>
      </div>
      {isDisplayModal && (
        <DownloadModal
          isOts={isOts}
          setIsDisplayModal={setIsDisplayModal}
          experience={lps?.name}
          finishedAt={finishedAt}
          skillList={getSkillNames(lspObjectives, knowledgeNodes)}
          themeList={lspObjectives.map(obj => obj?.name)}
        />
      )}
    </>
  )
}

export default EndOfExpCard

/**
 * @file Manages the Init app methods.
 */

import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { domUtils, domToolkit, DomoscioSdkLocales } from '@domoscio/domoscio-sdk-js'
import { Loader, DomUILocales } from '@domoscio/domoscio-ui'
import * as Errors from '../components/Errors'
import { Client, Session, Theme, User } from '../contexts/parameters'
import { domoscioAccess } from '../api/domoscio_access'
import { useInitialization } from './useInitialization'
import { getStorage } from '../storage'
import I18n from '../locales/I18n'
import AppLoader from '../components/app/AppLoader'
import * as Sentry from '@sentry/react'

/**
 * Initialize app client & user
 *
 * @category Components
 */
function Initializers({ children }: any) {
  // Get storage from browser context (try if sessionStorage is available)
  const storage = getStorage()
  // Contexts
  const client = React.useContext(Client.State)
  const clientDispatch = React.useContext(Client.Dispatch)
  // const theme = React.useContext(Theme.State)
  const themeDispatch = React.useContext(Theme.Dispatch)
  const userDispatch = React.useContext(User.Dispatch)
  const sessionDispatch = React.useContext(Session.Dispatch)

  // Hooks
  const [user, setUser] = React.useState({})
  const navigate = useNavigate()
  const location = useLocation()

  const useQuery = () => new URLSearchParams(location.search)
  const setTheme = (theme: any) =>
    themeDispatch(theme) && storage.setItem('theme', JSON.stringify(theme))

  const debugTrace = (trace: any) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(trace)
    }
  }

  /**
   * Append custom theme <style> tag to document.head
   */
  const appendTheme = (theme: any) => {
    const style = document.createElement('style')
    document.head.appendChild(style)

    style.innerHTML = domToolkit.themeToCss(theme)
  }

  /**
   * Apply parameters to LXP
   */
  const applyParameters = (parameters: any) => {
    if (parameters) {
      Sentry.setUser({
        id: parameters?.student_parameters?.uid,
        debugData: storage?.getItem('accesstoken')?.slice(0, 10)
      })

      const theme = domUtils.parametersToTheme(parameters?.client_parameters)
      setTheme(theme)
      appendTheme(theme)
      const studentParams = parameters?.student_parameters

      const formatedUserParams = {
        ...studentParams,
        ...studentParams?.user_parameter,
        ...studentParams?.account
      }
      I18n.locale = parameters?.student_parameters?.account.lang || 'en'
      DomUILocales.locale = I18n.locale
      DomoscioSdkLocales.locale = I18n.locale
      userDispatch({
        ...formatedUserParams,
        replayTour: { learningProgramShow: false, learningProgramIndex: false, adaptivePath: false }
      })
      setUser(formatedUserParams)
      sessionDispatch(parameters?.session_parameters)
      clientDispatch(parameters?.client_parameters || {})

      // Navigate to the same path requested & removed access_token parameter
      if (parameters?.session_parameters?.options?.lxp?.content_unavailable) {
        Errors.redirect('403')
      } else {
        navigate(location.pathname)
      }
    }
  }

  /**
   * Get access_token param
   */
  const getAccessToken = () => {
    const query = useQuery()
    const tokenParam = query.get('access_token')

    let accessToken = storage.getItem('accesstoken')
    if (accessToken === null && tokenParam === null) {
      // No AccessToken in storage or navigation params
      Errors.redirect('401')
      debugTrace('ERROR: No AccessToken found')
    }
    if (tokenParam !== null) {
      // Store AccessToken in browser
      accessToken = tokenParam
      storage.setItem('accesstoken', accessToken)
      Object.defineProperty(domoscioAccess, '_accessToken', {
        value: accessToken
      })
    }
    return accessToken
  }

  /**
   * Try to set parameters values by access token
   */
  const initialize = () => {
    // Check if client context state is empty, if true -> start Init
    if (Object.keys(client).length === 0) {
      const accessToken = getAccessToken()

      if (accessToken !== null) {
        // Start setup
        let jwt = storage.getItem('jwt') || ''
        // Call Access
        domoscioAccess.getParameters().then((parameters: any) => {
          if (!parameters) {
            Errors.redirect('internal', '', I18n.t('base.reload'))
          } else if (parameters?.statusCode === 401) {
            debugTrace(parameters?.body?.message)
          } else if (parameters?.statusCode === 401) {
            debugTrace(parameters?.body?.message)
          } else if (
            parameters !== null &&
            'session_parameters' in parameters &&
            'client_parameters' in parameters &&
            'student_parameters' in parameters
          ) {
            applyParameters(parameters)
            jwt = domUtils.encodeJWT(parameters, accessToken)
            storage.setItem('jwt', jwt)
          } else {
            Errors.redirect('500')
            debugTrace('ERROR: Invalid JWT token')
          }
        })
      }
    } else {
      debugTrace('ALERT: Client already init')
    }
  }

  /**
   * On mount || When user is set
   */
  React.useEffect((): any => {
    if (user && Object.keys(user).length === 0) {
      initialize()
    }
    // return user
  }, [user])

  const init = useInitialization(user)

  const ready = Object.keys(init?.parameters || {}).length !== 0
  const loadingProcesses = [{ name: 'user', isReady: ready }]

  // As long as not all screens are ready, display loader
  return (
    <AppLoader
      mandatoryProcesses={loadingProcesses}
      loadingComponent={<Loader />}
      minimumLoadingTime={1000}
    >
      {children}
    </AppLoader>
  )
}

export default Initializers

// Alternative storage method used when sessionStorage is unavailable
const customStorage = {
  setItem: (key: string, datas: string) => {
    const init = 'customStorage' in window
    if (!init) {
      // @ts-ignore
      window.customStorage = {}
    }
    // @ts-ignore
    window.customStorage[key] = datas
    return null
  },
  getItem: (key: string) => {
    // @ts-ignore
    if ('customStorage' in window && key in window.customStorage) {
      // @ts-ignore
      return window.customStorage[key]
    } else {
      return null
    }
  }
}

export const getStorage = () => {
  try {
    return sessionStorage || {}
  } catch (e) {
    return customStorage || {}
  }
}

/**
 * @file Display Application Error screen
 */

import * as React from 'react'
import { useDomToolkit } from '../../styles/dom-toolkit'
import { ReactComponent as Error } from '../../assets/errors/crash_server.svg'
import { DomButton } from '@domoscio/domoscio-ui'
import './errors.css'
import I18n from '../../locales/I18n'

export interface ErrorScreenProps {
  fill: string
  support?: string
}

/**
 * Renders the 401 Screen
 *
 * @category Screens
 */
function ErrorScreen({ fill = '#005eb8', support = 'support@domoscio.com' }: ErrorScreenProps) {
  const classes: any = useDomToolkit()

  const reload = () => {
    window.location.href = '/'
  }

  return (
    <div className={`errorContainer  ${classes.domTextPrimary}`}>
      <div className={'crashErrorBackground'}>CRASH</div>
      <div className={'errorContent'}>
        <div className={'crashErrorTitle'}>OOOUPS</div>
        <div className={'crashErrorContainer'}>
          <Error fill={fill} />
        </div>
        <div className={`${classes.domFlexPerfect}`}>
          <DomButton className={`${classes.domMarginRight}`} onClick={reload}>
            {I18n.t('errors.root')}
          </DomButton>
          <DomButton
            variant='secondary'
            className={`${classes.domMarginLeft}`}
            onClick={() => {
              window.location.href = 'mailto:' + support
            }}
          >
            {I18n.t('errors.support')}
          </DomButton>
        </div>
      </div>
    </div>
  )
}

export default ErrorScreen

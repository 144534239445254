import React from 'react'
import { MdOutlineAccessTimeFilled } from 'react-icons/md'
import { FaStar } from 'react-icons/fa'
import { HiViewGrid } from 'react-icons/hi'
type Props = {
  value: number
  title: string
  index: number
  iconColor: string
}

const StatAndTitle = ({ title, value, index, iconColor }: Props) => {
  const getIcon = () => {
    switch (index) {
      case 0:
        return <MdOutlineAccessTimeFilled color={iconColor} />
      case 1:
        return <HiViewGrid color={iconColor} />
      case 2:
        return <FaStar color={iconColor} />
      default:
        break
    }
  }
  return (
    <div className='statAndTitle'>
      <div className='line1'>
        <div className='col1'>{getIcon()}</div>
        <div style={{ color: iconColor === '#fff' ? '#f9f9f9' : '#474747' }} className='col2'>
          {value}
        </div>
      </div>
      <div style={{ color: iconColor === '#fff' ? ' #F9F9F9' : '#9696BD' }} className='line2'>
        {title}
      </div>
    </div>
  )
}

export default StatAndTitle

/**
 * @file Manages processed data on load with useEffect hook.
 */

import { useCallback, useEffect, useState } from 'react'

export function useInitialization(process) {
  const setInitialization = useCallback(async param => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setInit(prev => ({
      ...prev,
      parameters: param
    }))
  }, [])

  // State
  const [init, setInit] = useState({
    parameters: {},
    setInitialization
  })

  // Check data on load.
  useEffect(() => {
    async function initializeState() {
      const datas = process
      setInit(prev => ({
        ...prev,
        parameters: datas
      }))
    }
    initializeState()
  }, [process])

  return init
}

import React from 'react'
import { MdAccessTime, MdOutlineQuiz } from 'react-icons/md'
import { TbChevronRight } from 'react-icons/tb'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { FaRegStar } from 'react-icons/fa'
import './NextAction.scss'
import arrowMsc from '../../../../assets/imgs/arrowMsc.png'
import { useNavigate } from 'react-router-dom'
import I18n from '../../../../locales/I18n'
import { domoscioAccess } from '../../../../api/domoscio_access'

type Props = {
  themeName: string
  title: string
  tags: Tag[]
  subInfos: Tag[]
  primaryColor?: string
  url: string
}
type Tag = {
  type: 'doc' | 'quiz' | 'star' | 'time'
  value: string
}

const NextAction = ({ themeName, tags, title, subInfos, primaryColor, url }: Props) => {
  const isPrimary = primaryColor

  const navigate = useNavigate()

  const handleClick = () => {
    // kpi logs (feature tracking)
    domoscioAccess.postKpiLog('StudentNextAction')
    navigate(url)
  }

  return (
    <div>
      <div className='nextActionTitle'>{I18n.t('base.next_action')}</div>
      <div
        onClick={handleClick}
        className='nextAction listing_theme_step_2
 '
      >
        {tags.length > 0 && (
          <div className='col1'>
            {tags.map((tag, index) => {
              return (
                <div key={index}>
                  {index === 1 && <span className='separate'></span>}
                  <span
                    style={{
                      color: isPrimary ? primaryColor : '#6951e0'
                    }}
                    className='tagIconWrapper'
                  >
                    {tag.type === 'time' ? (
                      <MdAccessTime />
                    ) : tag.type === 'quiz' ? (
                      <MdOutlineQuiz />
                    ) : (
                      <IoDocumentTextOutline />
                    )}
                  </span>
                  <span className='tagValue'>{tag.value}</span>
                </div>
              )
            })}
          </div>
        )}
        <div className='col2'>
          <div className='nextActionHead'>
            <div>{title}</div>
            <div>{themeName}</div>
          </div>
          <div className='subInfos'>
            {subInfos.map((info, index) => {
              return (
                <div key={index} className='subInfo'>
                  <span
                    className='subInfoIcon'
                    style={{ color: isPrimary ? primaryColor : '#BF4DE8' }}
                  >
                    {info.type === 'doc' ? (
                      <IoDocumentTextOutline />
                    ) : info.type === 'quiz' ? (
                      <MdOutlineQuiz />
                    ) : (
                      <FaRegStar />
                    )}
                  </span>
                  <span className='subInfoText'>{info.value}</span>
                </div>
              )
            })}
          </div>
        </div>
        <div className='col3'>
          {isPrimary ? (
            <span style={{ borderColor: primaryColor }} className='arrowWrapper'>
              <TbChevronRight color={primaryColor} />
            </span>
          ) : (
            <img src={arrowMsc} />
          )}
        </div>
        <div
          style={{
            background: isPrimary
              ? primaryColor
              : 'linear-gradient(42.93deg, #BF4DE8 7.19%, #00FEDA 88.41%)'
          }}
          className='leftBar'
        ></div>
      </div>
    </div>
  )
}

export default NextAction

/* eslint-disable */

import React from 'react'
import { domoscioAccess } from '../../api/domoscio_access'
// import { updateUserJWT } from '../../storage/jwt'

// Context
const State = React.createContext()
const Dispatch = React.createContext()

const init = {}

// Reducer
const reducer = (state, nextState) => {
  const newState = {
    ...state,
    ...nextState
  }

  const stateAreEquals = JSON.stringify(state) === JSON.stringify(nextState)
  if (Object.keys(state).length !== 0 && !stateAreEquals) {
    let userParameters = (({ email, lang }) => ({
      email,
      lang
    }))(newState)

    if (!Object.keys(nextState).includes('email')) {
      delete userParameters.email
    }
  }

  return newState
}

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, init)

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  )
}

// Export
export const User = {
  State,
  Dispatch,
  Provider
}

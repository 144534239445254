import { LearningProgram, LearningProgramStudent } from '../../types/learninProgram'

// Match LPS with LP to fill full infos into learningProgramStudents object
export function assignLearningProgramInfos(
  learningPrograms: LearningProgram[] | undefined,
  learningProgramStudents: LearningProgramStudent[]
) {
  const learningProgramStudentsFilled: LearningProgramStudent[] = []

  // Ensure that learningPrograms is an array before using forEach
  if (Array.isArray(learningPrograms)) {
    // Loop through learningPrograms
    learningPrograms.forEach(lp => {
      // Match associated learningProgramStudents
      const match = learningProgramStudents.find(lps => lps.learning_program_uid === lp.uid)

      // Fill learningProgramStudents with LP name
      if (match) {
        learningProgramStudentsFilled.push({
          ...match,
          name: lp.name,
          nbr_knowledge_nodes: lp.nbr_knowledge_nodes,
          nbr_knowledge_graphs: lp.nbr_knowledge_graphs,
          image_url: lp.image_url,
          introduction: lp.introduction,
          is_ots: lp.is_ots,
          total_time: lp.total_time,
          has_certificate: lp.has_certificate
        })
      }
    })
  }

  return learningProgramStudentsFilled
}

/**
 * @file Manages the methods of the stateless AppLoader component.
 */

import React, { useEffect, useState } from 'react'

/**
 * A component used to display a loading while some processes are loading.
 */
function AppLoader({ ...props }: any) {
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(
    (props.minimumLoadingTime || 0) <= 0
  )

  // Handle potential minimum duration
  useEffect(() => {
    if (props.minimumLoadingTime) {
      setTimeout(() => setMinimumDurationPassed(true), props.minimumLoadingTime)
    }
  }, [])

  return (
    <>
      {props.mandatoryProcesses.every(process => process.isReady) && minimumDurationPassed
        ? props.children
        : props.loadingComponent}
    </>
  )
}

export default AppLoader

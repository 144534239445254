import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import './ErrorFallback.css'
import * as Errors from '../Errors'
import { Theme } from '../../contexts/parameters'
import * as Sentry from '@sentry/react'

interface AuxProps {
  children: any
}

function ErrorFallback({ error }: any) {
  const theme = React.useContext(Theme.State)
  const primaryColor = theme?.domBgPrimary
  Sentry.captureException(error)
  return <Errors.ErrorScreen fill={primaryColor} />
}

const ErrorWrapper = ({ children }: AuxProps) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
)

export default ErrorWrapper

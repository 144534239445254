/**
 * @file Manages the methods of the HomeScreen screen.
 */

import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Loader } from '@domoscio/domoscio-ui'
import { useDomToolkit } from '../styles/dom-toolkit'
import { useLearningDatas } from '../hooks/useLearningDatas'
import { useRestrictAccess } from '../hooks/access/useRestrictAccess'

const parentRoute = (url: string) => {
  const [parent] = url.split('/').slice(1)
  return url === '/' ? null : parent
}

/**
 * Renders the Home Screen
 *
 * @category Screens
 */
function HomeScreen() {
  // 1. Initialize classes & states
  const classes: any = useDomToolkit()

  // 2. Initialize route information
  const location = useLocation()
  const parent = parentRoute(location?.pathname)

  // 3. Fetch student learning datas
  const { loading } = useLearningDatas(true, parent)

  // 4. Verify that :id params is inside LearningContext
  useRestrictAccess(loading, parent)

  // If data are not ready, continue loading
  if (loading) {
    return <Loader />
  }

  return (
    <div
      className={`HomeScreen ${classes.domHeight100} ${classes.domFlex} ${classes.domFlexColumn}`}
    >
      <div className={`${classes.domMarginTop} ${classes.domFlex1}`}>
        <Outlet />
      </div>
    </div>
  )
}

export default HomeScreen

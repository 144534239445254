import { MdAccessTime, MdMoreTime, MdOutlineArrowDropDown, MdOutlineStar } from 'react-icons/md'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { FaRegStar, FaStar } from 'react-icons/fa'
import I18n from '../../locales/I18n'
import { LearningProgramStudent } from '../../types/learninProgram'
import { domUtils, lxpHubTypes, lxpHubUtils } from '@domoscio/domoscio-sdk-js'

export const prepareExpStats = (lps?: LearningProgramStudent) => {
  if (!lps) {
    return []
  }
  const timeSpent = lxpHubUtils.msToTime(lps?.time_spent || 0)
  const timeSaved = lxpHubUtils.msToTime(lps?.time_saved || 0)
  const skillAssessed = lps?.nbr_knowledge_nodes || 0
  const skillValidated = lps?.nb_knowledge_nodes_with_recommendations || 0
  const recoDone = lps?.nb_recommendations_followed || 0

  const toCapitalizeFirstLetter = (str: string) => {
    const result = str.split(' ').map((elem, index) => {
      if (index === 0) {
        return elem[0].toUpperCase() + elem.slice(1)
      } else {
        return elem
      }
    })
    return result.join(' ')
  }

  return [
    {
      data: timeSpent,
      icon: MdAccessTime,
      title: I18n.t('positioning_result.cards.time_spent', { count: 2 })
    },
    {
      data: timeSaved,
      icon: MdMoreTime,
      title: I18n.t('skill_assessment.saved_time', { count: 2 })
    },
    {
      data: skillAssessed,
      icon: FaRegStar,
      title: I18n.t('skill_assessment.worked_skills', { count: skillAssessed })
    },
    {
      data: skillValidated,
      icon: FaStar,
      title: toCapitalizeFirstLetter(
        I18n.t('skill_assessment.studied_skills', { count: skillValidated || 1 })
      )
    },
    {
      data: recoDone,
      icon: IoDocumentTextOutline,
      title: toCapitalizeFirstLetter(
        I18n.t('lxp_hub.learning_program_show.reco_done', { count: recoDone || 1 })
      )
    }
  ].filter((elem, index) => {
    if (index === 0 && (lps?.time_spent || 0) > 1000) {
      return true
    } else if (index === 1 && (lps.time_saved || 0) > 1000) {
      return true
    } else return Number(elem.data) > 0
  })
}

export const getBarLabels = (isOts: boolean) => {
  return [
    {
      label: I18n.t('lxp_hub.base.scale_levels.initial'),
      icon: undefined,
      color: isOts ? '#70B7FF' : '#ffd166'
    },
    {
      label: I18n.t('lxp_hub.base.scale_levels.final_other'),
      icon: undefined,
      color: isOts ? '#2B71B8' : '#82B7EB'
    },
    {
      label: I18n.t('lxp_hub.base.scale_levels.expected'),
      icon: { name: MdOutlineArrowDropDown, size: 22 },
      color: '#A7A7A7'
    },
    {
      label: I18n.t('lxp_hub.base.scale_levels.achieved'),
      icon: { name: MdOutlineStar, size: 17 },
      color: '#32D296'
    }
  ]
}

export const getSkillNames = (objectivesOfExp, knowledgeNodes) => {
  const result = objectivesOfExp
    .map(elem => Object.keys(elem.objective.validation_thresholds.knowledge_node_ids))
    .flat()
    .map(skill => knowledgeNodes.find(kn => kn.id === Number(skill))?.name || 'name not found')

  return result
}
export const sortBylastInteraction = (a: any, b: any) => {
  const valueA = new Date(a.last_interaction_at || a.ending_time).getTime()
  const valueB = new Date(b.last_interaction_at || b.ending_time).getTime()
  if (valueA > valueB) {
    return 1
  } else {
    return -1
  }
}

export const getExperienceLastInteractionDate = (
  objectiveStudens: lxpHubTypes.ObjectiveStudent[]
) => {
  const list = objectiveStudens
    .map(os => os.learning_sessions)
    .flat()
    .sort(sortBylastInteraction)
    .map(el => el.last_interaction_at || el.ending_time)
  return domUtils.convertDate(list[list.length - 1])
}

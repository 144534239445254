import React from 'react'
import { LearningContext } from '../types/learninProgram'

const init: LearningContext = {
  learningProgramStudents: [],
  learningPrograms: [],
  objectiveStudents: [],
  objectives: [],
  learningSessions: [],
  knowledgeNodes: []
}

// Reducer
const reducer = (state, nextState) => {
  const learning = {
    ...state,
    ...nextState
  }
  return learning
}

// Context
const State = React.createContext(init)
const Dispatch = React.createContext(reducer)

const Provider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, init)

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  )
}

const Learning = {
  State,
  Dispatch,
  Provider
}

const providers = [<Learning.Provider key='learning' />]

const LearningProvider = ({ children: initial }) =>
  providers.reduce((children, parent) => React.cloneElement(parent, { children }), initial)

export { LearningProvider, Learning }

import { HtmlToReact } from '@domoscio/domoscio-ui'
import React from 'react'
import { LearningProgramStudent } from '../../../../types/learninProgram'
import './Intro.scss'

type Props = {
  isDisplayIntro: boolean
  lps: LearningProgramStudent
}
const Intro = ({ isDisplayIntro, lps }: Props) => {
  return (
    <div className={`introVideo ${isDisplayIntro ? 'fullDisplayIntro' : ''}`}>
      {isDisplayIntro && <HtmlToReact html={lps?.introduction} />}
    </div>
  )
}

export default Intro

/**
 * @file Manages the NavigationContainer declaration (app routing) @see https://reactrouter.com/
 */

import React, { lazy, useContext, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Theme } from '../contexts/parameters'

import HomeScreen from '../screens/HomeScreen'
import * as LearningProgram from '../screens/LearningProgram'
// import * as LearningSession from '../screens/LearningSession'

import { Loader } from '@domoscio/domoscio-ui'
import * as Errors from '../components/Errors'

// Lazy loading (https://reactrouter.com/en/main/route/lazy)
const LearningSessionShow = lazy(() => import('../screens/LearningSession/LearningSessionShow'))
const PositionningPlayer = lazy(
  () => import('../screens/LearningSession/Positionning/PositionningPlayer')
)
const PositionningStart = lazy(
  () => import('../screens/LearningSession/Positionning/PositionningStart')
)
const PositionningResult = lazy(
  () => import('../screens/LearningSession/Positionning/PositionningResult')
)
const AdaptiveShow = lazy(() => import('../screens/LearningSession/Adaptive/AdaptiveShow'))
const AdaptivePath = lazy(() => import('../screens/LearningSession/Adaptive/AdaptivePath'))
const AdaptiveSkills = lazy(() => import('../screens/LearningSession/Adaptive/AdaptiveSkills'))
const AdaptiveContent = lazy(() => import('../screens/LearningSession/Adaptive/AdaptiveContent'))
const SettingsScreen = lazy(() => import('../screens/SettingsScreen/SettingsScreen'))
const SkillAssessment = lazy(() => import('../screens/SkillAssessment/SkillAssessment'))

/**
 * Navigation <Routes> declaration component
 *
 * @category Components
 */
function Navigation() {
  // ErrorsScreens color
  const theme = useContext(Theme.State)
  const fill = '#' + theme?.domBgPrimary.match(/[0-9A-Fa-f]{6}/g)

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path='/' element={<HomeScreen />}>
          {/* LearningPrograms */}
          <Route index element={<LearningProgram.Index />} />
          <Route path='/learning_programs/:id' element={<LearningProgram.Show />} />
          <Route path='/learning_programs/:id/skill_assessment' element={<SkillAssessment />} />

          {/* LearningSessions */}
          <Route path='/learning_sessions/:id' element={<LearningSessionShow />}>
            {/* Positionning */}
            <Route
              path='/learning_sessions/:id/positionning/player'
              element={<PositionningPlayer />}
            />
            <Route
              path='/learning_sessions/:id/positionning/start'
              element={<PositionningStart />}
            />
            <Route
              path='/learning_sessions/:id/positionning/result'
              element={<PositionningResult />}
            />
            {/* Adaptive */}
            <Route path='/learning_sessions/:id/adaptive' element={<AdaptiveShow />}>
              <Route index element={<AdaptivePath />} />
              <Route path='/learning_sessions/:id/adaptive/skills' element={<AdaptiveSkills />} />
            </Route>
            <Route path='/learning_sessions/:id/content/:contentId' element={<AdaptiveContent />} />
          </Route>

          <Route path='/settings' element={<SettingsScreen />} />
        </Route>

        {/* Errors path handlers */}
        <Route path='401' element={<Errors.AccessDeniedScreen fill={fill} />} />
        <Route path='*' element={<Errors.NotFoundScreen fill={fill} />} />
        <Route path='500' element={<Errors.InternalErrorScreen fill={fill} />} />
        <Route path='error' element={<Errors.ErrorScreen fill={fill} />} />
        <Route path='domoscio' element={<Errors.FallbackScreen />} />
      </Routes>
    </Suspense>
  )
}

export default Navigation

import React, { useContext } from 'react'
import { Client } from '../../contexts/parameters'
import './ButtonOts.scss'
type Props = {
  variant: 'first' | 'second'
  children: any
  handleClick?: (arg?: any) => void
  disabled?: boolean
  isSending?: boolean
  isOts: boolean
  className?: string
}

const LoaderWhite = () => {
  return (
    <svg
      className='loaderLight'
      version='1.1'
      id='loader-1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='40px'
      height='40px'
      viewBox='0 0 50 50'
      xmlSpace='preserve'
    >
      <path
        fill='#fff'
        d='M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z'
      >
        <animateTransform
          attributeType='xml'
          attributeName='transform'
          type='rotate'
          from='0 25 25'
          to='360 25 25'
          dur='0.6s'
          repeatCount='indefinite'
        />
      </path>
    </svg>
  )
}
const ButtonOts = ({
  children,
  variant,
  handleClick,
  disabled,
  isSending,
  isOts,
  className
}: Props) => {
  const client = useContext(Client.State)
  const primaryColor = client.mqb_primary_color

  return (
    <div
      onClick={handleClick}
      style={{
        pointerEvents: disabled ? 'none' : 'auto',
        cursor: disabled ? 'not-allowed' : 'pointer',
        borderColor: !isOts ? primaryColor : '#6C51F6',
        color: variant === 'first' ? '#fff' : !isOts ? primaryColor : '#6C51F6',
        backgroundColor: variant === 'second' ? '#fff' : !isOts ? primaryColor : '#6C51F6'
      }}
      className={`buttonOts buttonOts_${variant} buttonOts_${variant}_${
        !isOts ? 'primaryColor' : 'otsColor'
      }
      } ${isSending ? 'buttonOtsLoading' : ''} ${className || ''}`}
    >
      {isSending ? <LoaderWhite /> : children}
    </div>
  )
}

export default ButtonOts

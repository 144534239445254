import React, { useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { MdHome } from 'react-icons/md'
import { ListingCards, Loader, NoResult, ThemeCard } from '@domoscio/domoscio-ui'
import { lxpHubUtils } from '@domoscio/domoscio-sdk-js'
import { Learning } from '../../contexts/learning'
import { Client, Session, User } from '../../contexts/parameters'
import { useDomToolkit } from '../../styles/dom-toolkit'
import { useNextAction } from '../../hooks/useLearningProgram'
import { byObjectiveId, byObjectiveStatus, updateIntro } from './utils'
import I18n from '../../locales/I18n'
import HomeNav from '../../components/navigation/HomeNav'
import './LearningProgram.scss'
import Footer from '../../components/Footer/Footer'
import DashboardHero from './subComponents/DashboardHero/DashboardHero'
import NextAction from './subComponents/NextAction/NextAction'
import EndOfExpCard from './subComponents/EndOfExpCard/EndOfExpCard'
import Intro from './subComponents/Intro/Intro'
import { domoscioAccess } from '../../api/domoscio_access'
import * as Tours from '../../components/Tours'
type FilterValues = 'toDo' | 'done' | 'doing' | 'pending' | 'upcoming'

const LearningProgramShow = () => {
  // Filters
  const [filters, setFilters] = useState<{ search: string; status: 'all' | FilterValues }>({
    search: '',
    status: 'all'
  })

  const [isDisplayIntro, setIsDisplayIntro] = useState(false)

  // Hooks
  const params = useParams()

  const classes: any = useDomToolkit()
  const { learningProgramStudents, objectiveStudents, knowledgeNodes } = useContext(Learning.State)
  const client = useContext(Client.State)
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)
  // Context
  const session = useContext(Session.State)
  const lpsContext = session?.options?.lxp?.learning_program_student_id !== undefined

  // Find page objects
  const lps = learningProgramStudents.find(lps => lps.id === Number(params.id))

  const lpsObjectives = objectiveStudents
    .filter(os => os?.learning_program_id === lps?.learning_program_id)
    .sort(byObjectiveId)

  // Get lps next action
  const { nextAction, nextActionLoading } = useNextAction(lps)
  const { url } = nextAction

  // Status to use when filtering experience
  const themeCardDatas = lpsObjectives
    .map(os => lxpHubUtils.prepareThemeCardDatas(os as any))
    .sort(byObjectiveStatus)

  const inputSelectOptions = useMemo(() => lxpHubUtils.createFilterInputs(themeCardDatas), [])

  const filteredList = themeCardDatas.filter(elem => {
    if (filters.status === 'all') {
      return lxpHubUtils.isTextMatches(filters.search, elem.title)
    } else {
      return (
        elem.statusText.id === filters.status &&
        lxpHubUtils.isTextMatches(filters.search, elem.title)
      )
    }
  })

  // 👇 Display of introduction of experience, if not seen => display
  useLayoutEffect(() => {
    const lpsIntroId = lps?.id
    const introList = user.onboarding?.lxp_hub?.learning_program_introduction || []
    const isIntroSeen = introList.includes(lpsIntroId)

    if (lps?.introduction && !isIntroSeen) {
      const newOnboarding = updateIntro(user.onboarding, lpsIntroId)
      userDispatch({ ...user, onboarding: newOnboarding })
      domoscioAccess.updateUserParameter({ onboarding: newOnboarding })
      setIsDisplayIntro(true)
    } else {
      setIsDisplayIntro(false)
    }
  }, [lps])

  // 👇 wait for next action laading, this is important for onboarding tour
  if (nextActionLoading) {
    return <Loader />
  }

  if (!lps) {
    return <div>{I18n('settingsScreen.error_generic')}</div>
  }

  const primaryColor = client.mqb_primary_color
  const isPrimary = !lps?.is_ots
  const highlightedThemId = nextAction?.id

  return (
    <Tours.LearningProgramShow
      isOts={!isPrimary}
      isDisplayStep2={lps.next_action && Object.keys(lps?.next_action).length > 0}
    >
      <div className='LearningProgramShow'>
        <section className='darkBg'>
          {/* Heading */}
          <div className='headerTitle'>
            {
              <div className='title'>
                <span className='homeIcon'>
                  <span
                    style={{ backgroundColor: isPrimary ? primaryColor : '#6951E0' }}
                    className='homeIconWrapper'
                  >
                    {' '}
                    <MdHome size={20} />
                  </span>
                </span>
                <span>{I18n.t('base.my_experience')}</span>
              </div>
            }
            <HomeNav />
          </div>
          <DashboardHero
            isDisplayBackButton={!lpsContext}
            isDisplayIntro={isDisplayIntro}
            setIsDisplayIntro={setIsDisplayIntro}
            lps={lps}
            primaryColor={isPrimary ? primaryColor : undefined}
          />
          <Intro isDisplayIntro={isDisplayIntro} lps={lps} />
          {lps.hub_progression === 100 && (
            <EndOfExpCard
              lspObjectives={lpsObjectives}
              lps={lps}
              primaryColor={isPrimary ? primaryColor : undefined}
              isOts={!isPrimary}
            />
          )}
          {/* Next Action */}
          {url && !nextActionLoading && (
            <NextAction
              themeName={nextAction.themeName}
              title={nextAction.title}
              tags={nextAction.tags}
              subInfos={nextAction.subInfos}
              primaryColor={isPrimary ? primaryColor : undefined}
              url={url}
            />
          )}
          {/* Objectives */}
          <div className='listingThemeTitle'>
            {/* Title */}
            <div className='titleThemeList'>
              <span>{I18n.t('learning_program_show.objectives.title')}</span>
            </div>
            {/* Filters */}
            <div className={`${classes.domFlex1} ${classes.domFlexRight}`}>
              <ListingCards
                filters={filters}
                onChange={setFilters}
                inputSelectOptions={inputSelectOptions}
              />
            </div>
          </div>
        </section>
        <div className='objectives'>
          {filteredList.length === 0 ? (
            <NoResult />
          ) : knowledgeNodes.length === 0 ? (
            <Loader />
          ) : (
            <div className='themeList'>
              {filteredList.map((objective, index) => (
                <NavLink
                  key={index}
                  to={objective.lsAId ? `/learning_sessions/${objective.lsAId}/adaptive` : '/'}
                  style={{
                    pointerEvents: objective.isClickable ? 'auto' : 'none'
                  }}
                  className={`${index === 0 ? 'listing_theme_step_3' : ''}`}
                >
                  <div className={'cardContainer'}>
                    <ThemeCard
                      cardStatus={objective.cardStatus}
                      statusText={objective.statusText}
                      primaryColor={primaryColor}
                      progress={objective.progress}
                      title={objective.title}
                      timeInfo={objective.timeInfo}
                      subInfos={objective.subInfos}
                      isOts={!isPrimary}
                      isHighLighted={objective.objectiveId === highlightedThemId}
                      imageUrl={objective.imageUrl}
                    />
                  </div>
                </NavLink>
              ))}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </Tours.LearningProgramShow>
  )
}

export default LearningProgramShow

import React from 'react'
import * as ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'

import './styles/index.css'
import App from './App'
import reportWebVitals from './core/reportWebVitals'
import { ModalProvider } from './contexts/ModalProvider'
import { AnimationContextProvider } from './contexts/animations'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

const env = process.env.REACT_APP_DOMOSCIO_ENV || 'dev'
if (env !== 'dev') {
  Sentry.init({
    dsn: 'https://e8732a274ca042b89ad7b8c6b7622433@o1022181.ingest.sentry.io/4503935839240192',
    normalizeDepth: 10,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay({
        blockAllMedia: true,
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [/domoscio/]
      })
    ],
    // beforeSend called immediately before the event is sent to the server
    beforeSend: (event, hint) => {
      const error = hint.originalException as any
      // Don't need to trace "Invariant failed" errors not a real error for our us case => return null,
      if (error?.message === 'Invariant failed') {
        return null
      } else {
        return event
      }
    },
    ignoreErrors: ['NoContentError', 'change_ua', 'Non-Error exception captured'],
    tracesSampleRate: 1.0,
    environment: env,
    release: '0.1.0',
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  })
}

export const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
  <BrowserRouter>
    <ModalProvider>
      <AnimationContextProvider>
        <App />
      </AnimationContextProvider>
    </ModalProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

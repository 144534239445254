/**
 * @file Manages the postMessage analytics clientHeight methods.
 */

import React from 'react'

function ClientHeightObserver({ children }) {
  /**
   * Post cross-origin communication in domoscio object
   */
  const sendAnalyticsMessage = () => {
    const clientHeight = window.document.body.clientHeight

    window.parent.postMessage({ domoscio: { analytics: { clientHeight: clientHeight } } }, '*')
  }

  /**
   * Observer instance (detect document height change)
   */
  const resizeObserver = new ResizeObserver(
    entries => sendAnalyticsMessage() // entries[0].target.clientHeight
  )

  // Init hooks
  React.useEffect(() => {
    // 1 : Post first message
    sendAnalyticsMessage()

    // 2 : On windows resize -> send updated height
    window.addEventListener('resize', sendAnalyticsMessage)

    // 3 : On body resize -> send updated height
    resizeObserver.observe(window.document.body)

    // 4 : Remove windows resize listener
    return () => window.removeEventListener('resize', sendAnalyticsMessage)
  }, [])

  return children
}

export default ClientHeightObserver

import { lxpHubTypes } from '@domoscio/domoscio-sdk-js'
import { domoscioAccess } from '../api/domoscio_access'
import { LearningProgram, LearningProgramStudent } from '../types/learninProgram'

// Get student scoped learning_program_students
export async function getLearningProgramStudents(
  studentUid: string,
  learningProgramStudentId?: number
) {
  const body = { student_uid: studentUid } as any

  // scope by learning program studen
  if (learningProgramStudentId) {
    body.id = [learningProgramStudentId]
  }

  return await domoscioAccess.getLearningProgramStudents(body)
}

// Get student scoped learning_programs infos
export async function getLearningPrograms(learningProgramStudents: LearningProgramStudent[]) {
  const uids: Array<string> = learningProgramStudents
    ? learningProgramStudents.map(lps => lps.learning_program_uid)
    : []
  return await domoscioAccess.getLearningPrograms({ uid: uids, with_details: true })
}

// Get student scoped objectives infos
export async function getObjectives(learningPrograms: LearningProgram[]) {
  const ids: Array<Number> = learningPrograms ? learningPrograms.map(lp => lp.id) : []
  return await domoscioAccess.getObjectives({ learning_program_id: ids })
}

// Get objective scoped knowledge_nodes infos
export async function getKnowledgeNodes(objectives: lxpHubTypes.Objective[]) {
  if (objectives) {
    const knowledgeGraphs: Number[][] = objectives.map(obj =>
      Object.keys(obj.validation_thresholds.knowledge_node_ids).map(elem => Number(elem))
    )
    return await domoscioAccess.getKnowledgeNodes({ id: knowledgeGraphs.flat() })
  } else {
    return []
  }
}

// Get student scoped objective_students infos
async function getObjectiveStudents(studentUid: string, objectiveIds: number[]) {
  return await domoscioAccess.getObjectiveStudents({
    student_uid: studentUid,
    objective_id: objectiveIds
  })
}

// Get student scoped learning_sessions infos
async function getLearningSessions(studentUid: string, objectiveIds: number[]) {
  return await domoscioAccess.getLearningSessions({
    student_uid: studentUid,
    objective_id: objectiveIds
  })
}

export async function fetchStudentLearningDatas(studentUid: string, objectiveIds: number[]) {
  return {
    objectiveStudents: await getObjectiveStudents(studentUid, objectiveIds),
    learningSessions: await getLearningSessions(studentUid, objectiveIds)
  }
}

import React from 'react'
import { MdArrowBack } from 'react-icons/md'
import { useDomToolkit } from '../../styles/dom-toolkit'

export interface ModalProps {
  modal: React.ReactNode | string
  title?: string
  unSetModal?: () => void | void | undefined
}

// @see https://hesambayat.com/how-to-create-dialog-boxes-with-react-hook-and-react-context/
const Modal = ({ modal, title, unSetModal }: ModalProps) => {
  React.useEffect(() => {
    const bind = (e: any) => {
      if (e.keyCode !== 27) {
        return
      }

      if (document.activeElement && ['INPUT', 'SELECT'].includes(document.activeElement.tagName)) {
        return
      }

      if (typeof unSetModal === 'function') {
        unSetModal()
      }
    }

    document.addEventListener('keyup', bind)
    return () => document.removeEventListener('keyup', bind)
  }, [modal, unSetModal])

  const classes: any = useDomToolkit()

  return (
    <div className='app-modal absolute top-0 z-50 w-full'>
      <div className='h-screen w-full z-10 inset-0 overflow-hidden bg-white'>
        {/* <div className='absolute w-full h-full inset-0 bg-gray-500 opacity-75'></div> */}
        <div className='flex flex-col min-h-screen px-4 py-4 text-center sm:block sm:p-0'>
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          ></span>
          <div
            className='inline-block relative transform transition-all sm:align-middle sm:max-w-lg'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'
          >
            {/* Heading */}
            <div className={`${classes.domFlex} ${classes.domFlexMiddle}`}>
              <button
                onClick={unSetModal}
                className={`back-button ${classes.domBgPrimary} ${classes.domBorder0} ${classes.domCursorPointer}`}
              >
                <MdArrowBack size={24} color={'#fff'} />
              </button>
              {title && (
                <div className={`${classes.domMarginLeft} ${classes.domText20}`}>{title}</div>
              )}
            </div>

            {/* Modal container */}
            <div className='p-4'>{modal}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal

import { lxpHubTypes } from '@domoscio/domoscio-sdk-js'

// Match OS with OBJ to fill full infos into objectiveStudents object
export function assignObjectivesInfos(
  objectives: lxpHubTypes.Objective[],
  objectiveStudents: lxpHubTypes.ObjectiveStudent[],
  learningSessions: lxpHubTypes.LearningSession[]
) {
  const objectiveStudentsFilled: any[] = []

  // Loop through objectives
  objectives.forEach(obj => {
    // Match associated objectiveStudents
    const match = objectiveStudents.find(os => os.objective_id === obj.id)
    const matchLearningSessions = learningSessions?.filter(ls => ls.objective_id === obj.id) || []

    // Fill objectiveStudents with Obj name
    if (match) {
      objectiveStudentsFilled.push({
        ...match,
        objective: obj,
        name: obj.name,
        image_url: obj.image_url,
        learning_program_id: obj.learning_program_id,
        learning_sessions: matchLearningSessions
      })
    }
  })

  return objectiveStudentsFilled
}

import * as React from 'react'

import AccessDeniedScreen from './AccessDeniedScreen'
import ErrorScreen from './ErrorScreen'
import FallbackScreen from './FallbackScreen'
import InternalErrorScreen from './InternalErrorScreen'
import NotFoundScreen from './NotFoundScreen'
import ContentUnavailableErrorScreen from './ContentUnavailableErrorScreen'
import { root } from '../../index'

export function redirect(code: string, customMessage?: string, buttonLabel?: string) {
  switch (code) {
    case '401':
      root.render(React.createElement(AccessDeniedScreen))
      break
    case '403':
      root.render(React.createElement(ContentUnavailableErrorScreen))
      break
    case '404':
      root.render(React.createElement(NotFoundScreen))
      break
    case '500':
      root.render(React.createElement(ErrorScreen))
      break
    case 'internal':
      root.render(<InternalErrorScreen buttonLabel={buttonLabel} customMessage={customMessage} />)
      break
    default:
      break
  }
}

export {
  AccessDeniedScreen,
  ErrorScreen,
  FallbackScreen,
  InternalErrorScreen,
  NotFoundScreen,
  ContentUnavailableErrorScreen
}

import React, { ReactNode } from 'react'
import ReactJoyride from 'react-joyride'
import { OnboardingTooltip } from '@domoscio/domoscio-ui'
import { User } from '../../contexts/parameters'
import I18n from '../../locales/I18n'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { domoscioAccess } from '../../api/domoscio_access'

type Props = {
  children: ReactNode
}

const steps = () => {
  return [
    {
      title: I18n.t(`tour.adaptive_content.title_step_${1}`),
      target: `.adaptive_content_step${1}`,
      content: I18n.t(`tour.adaptive_content.step_${1}`),
      disableBeacon: true,
      placement: 'top' as any
    }
  ]
}

const AdaptiveContent = ({ children }: Props) => {
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)

  let runTour = true
  if (user.onboarding?.lxp_hub?.adaptiveContent) {
    runTour = false
  }

  const handleCallbackJoyRide = (data: any) => {
    const { action, status } = data

    // Send finished tour to server
    if (status === 'finished' || action === 'reset') {
      const newOnboarding = domUtils.updateTour(user.onboarding, 'lxp_hub', {
        adaptiveContent: true
      })
      if (!user?.lxp_hub?.adaptiveContent) {
        userDispatch({ ...user, onboarding: newOnboarding })
        domoscioAccess.updateUserParameter({ onboarding: newOnboarding })
      } else {
        userDispatch({ ...user, replayTour: { ...user.replayTour, adaptiveContent: false } })
      }
    }
  }

  return (
    <div className='tourWrapper'>
      <ReactJoyride
        floaterProps={{ disableAnimation: true }}
        tooltipComponent={OnboardingTooltip}
        steps={steps()}
        continuous={true}
        run={user.replayTour.adaptiveContent || runTour}
        styles={{
          options: {
            zIndex: 10000
          },
          spotlight: {
            borderRadius: 15
          }
        }}
        callback={arg => handleCallbackJoyRide(arg)}
      />
      {children}
    </div>
  )
}

export default AdaptiveContent

import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  Defs,
  LinearGradient,
  Rect,
  Stop,
  Svg
} from '@react-pdf/renderer'
import I18n from '../../../../../locales/I18n'
import { CertificateImg, styles } from './CerificateComponents'

type Props = {
  name: string
  experience: string
  skillCount: number
  id: number | string
  finishedAt: string
  isOts?: boolean
  primaryColor?: string
  skillList: Array<string>
  skillNameLineCount: (1 | 2)[]
  isSkillBased: boolean
}

const CertificateRenderer = ({
  name,
  experience,
  skillCount,
  finishedAt,
  id,
  isOts = true,
  primaryColor,
  skillList,
  skillNameLineCount,
  isSkillBased
}: Props) => {
  const list = [[], [], []] as Array<Array<string>>

  const prepareSkillNameColumns = skillList => {
    let count = 0
    let col = 0
    skillList.forEach((element, index) => {
      if (skillNameLineCount[index] + count <= 8) {
        list[col].push(element)
        count += skillNameLineCount[index]
      } else if (col < 2) {
        count = skillNameLineCount[index]
        col += 1
        list[col].push(element)
      }
    })
    return list
  }

  function getTextWidth(text: string) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      context.font = '15px'
      return Number(context.measureText(text).width.toFixed(0))
    } else {
      return 0
    }
  }

  // 👇 funtion to calculate skill list column width based on max text size of skill name in the column
  const getDynamicColumnWidth = (maxTextSize: number) => {
    const dynamcicCalculation = (offset: number) => maxTextSize + 50 * (maxTextSize / offset)
    if (maxTextSize > 192) {
      return '31%'
    } else if (maxTextSize > 130) {
      return dynamcicCalculation(192)
    } else if (maxTextSize > 90) {
      return dynamcicCalculation(150)
    } else if (maxTextSize > 60) {
      return dynamcicCalculation(120)
    } else if (maxTextSize > 30) {
      return dynamcicCalculation(80)
    } else {
      return dynamcicCalculation(60)
    }
  }

  return (
    <Document>
      <Page
        orientation='landscape'
        size='A4'
        style={[styles.page, { backgroundColor: isOts ? '#5BAAE1' : '#fff' }]}
      >
        {isOts ? (
          <Svg
            style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
            viewBox='0 0 0 0'
            width='100%'
            height='100%'
          >
            <Defs>
              <LinearGradient id='myLinearGradient' x1='0' x2='0' y1='0' y2='0'>
                <Stop stopOpacity='1' offset='10%' stopColor='#bf4ee9' />
                <Stop stopOpacity='0.1' offset='100%' stopColor='#3AC8DF' />
              </LinearGradient>
            </Defs>

            <Rect fill="url('#myLinearGradient')" x='0' y='0' width='2480' height='3508' />
          </Svg>
        ) : (
          <View style={[styles.banner, { backgroundColor: primaryColor }]}></View>
        )}
        <View style={{ padding: 40, paddingBottom: 20 }}>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.colLeft}>
              <View
                style={[
                  styles.idSection,
                  { color: isOts ? '#f9f9f9' : '#7F7F7F', marginTop: isOts ? -15 : -5 }
                ]}
              >
                <Text>{I18n.t('skill_assessment.certificate.id') + id}</Text>
              </View>
              <View style={[styles.header, !isOts ? styles.headerOther : {}]}>
                <Text>{I18n.t('learning_program_index.status.done')}</Text>
              </View>
              <View style={[styles.userName, !isOts ? styles.userNameOther : {}]}>
                <Text style={{ maxLines: 2, textOverflow: 'ellipsis', lineHeight: 1.2 }}>
                  {name}
                </Text>
              </View>
              <View style={[styles.expName, !isOts ? styles.expNameOther : {}]}>
                <Text
                  style={{
                    maxLines: 2,
                    lineHeight: 1.2,
                    textOverflow: 'ellipsis'
                  }}
                >
                  {experience}
                </Text>
              </View>
              <View style={[styles.expSeparator, !isOts ? styles.expSeparatorOther : {}]} />
            </View>
            <View style={styles.colRight}>
              <View style={{ color: isOts ? '#F9F9F9' : '#505050' }}>
                <Text style={styles.endDate1}>
                  {I18n.t('skill_assessment.certificate.finished_at')}
                </Text>
                <Text style={styles.endDate2}>{finishedAt}</Text>
              </View>
              <View style={styles.certificateWrapper}>
                <CertificateImg
                  color={isOts ? '#A8A9ED' : primaryColor}
                  styles={{ width: 120, height: 112 }}
                />
              </View>
            </View>
          </View>

          <Text
            style={{
              color: isOts ? '#f9f9f9' : '#505050',
              fontWeight: 'bold',
              fontFamily: 'Dm Sans'
            }}
          >
            {skillCount +
              ' ' +
              I18n.t(`skill_assessment.certificate.${isSkillBased ? 'skills' : 'themes'}.title`, {
                count: skillCount || 1
              })}
          </Text>
          <View
            style={{
              marginTop: 20,
              flexDirection: 'row',
              width: '100%'
            }}
          >
            {prepareSkillNameColumns(skillList).map((el, i) => {
              const columnWidth = el.map(text => getTextWidth(text)).sort((a, b) => b - a)[0]
              return (
                <View
                  key={i}
                  style={{
                    width: getDynamicColumnWidth(columnWidth),
                    height: 140,
                    marginRight: i === 2 ? 0 : 20
                  }}
                >
                  {el.map((skill, index) => (
                    <View
                      style={{
                        flexDirection: 'row',
                        lineHeight: 1.2
                      }}
                      key={index}
                    >
                      <View
                        style={{
                          width: 4,
                          height: 4,
                          minWidth: 4,
                          borderRadius: '50%',
                          backgroundColor: isOts ? '#f9f9f9' : '#505050',
                          marginRight: 7,
                          marginTop: 5
                        }}
                      ></View>
                      <Text
                        style={{
                          fontSize: 11,
                          color: isOts ? '#f9f9f9' : '#505050',
                          maxLines: 2,
                          width: columnWidth > 192 ? 240 : columnWidth + 50,
                          lineHeight: 1.4,
                          fontWeight: 500,
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {skill}
                      </Text>
                    </View>
                  ))}
                </View>
              )
            })}
          </View>
          {/* {isOts && <View style={styles.bottom}></View>} */}
        </View>
      </Page>
    </Document>
  )
}

export default CertificateRenderer

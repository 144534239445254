import React, { ReactNode, useEffect, useState } from 'react'
import ReactJoyride from 'react-joyride'
import { OnboardingTooltip } from '@domoscio/domoscio-ui'
import { User } from '../../contexts/parameters'
import I18n from '../../locales/I18n'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { domoscioAccess } from '../../api/domoscio_access'

type Props = {
  children: ReactNode
  isScreenReadyForTour: boolean
}

const steps = () => {
  return [1, 2].map(elem => ({
    title: I18n.t(`tour.tsr.title_step_${elem}`),
    target: `.tsr_step${elem}`,
    content: I18n.t(`tour.tsr.step_${elem}`),
    disableBeacon: true,
    placement: elem === 1 ? ('bottom' as any) : ('top' as any)
  }))
}

const PositionningResult = ({ children, isScreenReadyForTour }: Props) => {
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)
  const [runTour, setRunTour] = useState(false)

  useEffect(() => {
    if (!user.onboarding?.lxp_hub?.positionningResult && isScreenReadyForTour) {
      setRunTour(true)
    }
  }, [isScreenReadyForTour])

  const handleCallbackJoyRide = (data: any) => {
    const { action, status } = data

    // Send finished tour to server
    if (status === 'finished' || action === 'reset') {
      const newOnboarding = domUtils.updateTour(user.onboarding, 'lxp_hub', {
        positionningResult: true
      })
      if (!user.onboarding?.lxp_hub?.positionningResult) {
        userDispatch({ ...user, onboarding: newOnboarding })
        domoscioAccess.updateUserParameter({ onboarding: newOnboarding })
      } else {
        userDispatch({ ...user, replayTour: { ...user.replayTour, positionningResult: false } })
      }
    }
  }

  const createSteps = () => {
    let allSteps = steps()
    const step2 = document.querySelector('.tsr_step2')
    if (!step2) {
      allSteps.splice(1, 1)
      allSteps = allSteps.map((elem, i) => ({
        ...elem,
        title:
          I18n.t('tour.learning_program_show.title_step_1').split(' ')[0] +
          ' ' +
          `${i + 1}/${allSteps.length}`
      }))
    }
    return allSteps
  }

  return (
    <div className='tourWrapper'>
      <ReactJoyride
        floaterProps={{ disableAnimation: true }}
        tooltipComponent={OnboardingTooltip}
        steps={createSteps()}
        continuous={true}
        run={isScreenReadyForTour && (user.replayTour.positionningResult || runTour)}
        styles={{
          options: {
            zIndex: 10000
          },
          spotlight: {
            borderRadius: 20
          }
        }}
        callback={arg => handleCallbackJoyRide(arg)}
      />
      {children}
    </div>
  )
}

export default PositionningResult

import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Learning } from '../../contexts/learning'

export function useRestrictAccess(loading: boolean, object: string | null) {
  // Routing params
  const params = useParams()
  const navigate = useNavigate()

  // Fetching learning context
  const context = useContext(Learning.State)
  const { learningProgramStudents, learningSessions } = context

  let access = true
  if (!loading) {
    // Skip if loading
    if (object === 'learning_programs') {
      // LearningProgram
      const lps = learningProgramStudents.find((lps: any) => lps.id.toString() === params.id)
      access = lps !== undefined
    } else if (object === 'learning_sessions') {
      // LearningSession
      const ls = learningSessions.find((ls: any) => ls.id.toString() === params.id)
      access = ls !== undefined
    }
  }

  // If access is denied : redirect to homepage
  useEffect(() => {
    if (!access) {
      navigate('/')
    }
  }, [access, navigate])

  return access
}

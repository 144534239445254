/**
 * @file Display Access Denied 401 error screen
 */

import * as React from 'react'
import { useDomToolkit } from '../../styles/dom-toolkit'
import { ReactComponent as AccessDenied } from '../../assets/errors/access_denied.svg'
import './errors.css'
import I18n from '../../locales/I18n'

export interface AccessDeniedScreenProps {
  fill: string
}

/**
 * Renders the 401 Screen
 *
 * @category Screens
 */
function AccessDeniedScreen({ fill = '#005eb8' }: AccessDeniedScreenProps) {
  const classes: any = useDomToolkit()

  return (
    <div className={'errorContainer'}>
      <div className={'errorBackground'}>401</div>
      <div className={'errorContent'}>
        <div className={`accessDeniedTitle ${classes.domTextPrimary}`}>
          {I18n.t('errors.access_denied')}
        </div>
        <div className={'accessDeniedContainer'}>
          <AccessDenied fill={fill} />
        </div>
      </div>
    </div>
  )
}

export default AccessDeniedScreen

/**
 * @file Display Application Error screen
 */

import * as React from 'react'
import { useDomToolkit } from '../../styles/dom-toolkit'
import { ReactComponent as InternalError } from '../../assets/errors/internal_error.svg'
import { DomButton } from '@domoscio/domoscio-ui'
import './errors.css'
import I18n from '../../locales/I18n'

export interface InternalErrorScreenProps {
  fill?: string
  support?: string
  buttonLabel?: string
  customMessage?: string
}

/**
 * Renders the 401 Screen
 *
 * @category Screens
 */
function InternalErrorScreen({
  fill = '#005eb8',
  support = 'support@domoscio.com',
  customMessage,
  buttonLabel
}: InternalErrorScreenProps) {
  const classes: any = useDomToolkit()

  const reload = () => {
    window.location.href = '/'
  }

  return (
    <div className={`errorContainer  ${classes.domTextPrimary}`}>
      <div className={'errorBackground'}>500</div>
      <div className={'errorContent'}>
        <div className={'internalErrorTitle'}>
          {customMessage || I18n.t('errors.internal_error')}
        </div>
        <div className={'internalErrorContainer'}>
          <InternalError fill={fill} />
        </div>
        <div className={`${classes.domFlexPerfect}`}>
          <DomButton className={`${classes.domMarginLargeRight}`} onClick={() => reload()}>
            {buttonLabel || I18n.t('errors.root')}
          </DomButton>
          <DomButton
            variant='secondary'
            onClick={() => {
              window.location.href = 'mailto:' + support
            }}
          >
            {I18n.t('errors.support')}
          </DomButton>
        </div>
      </div>
    </div>
  )
}

export default InternalErrorScreen

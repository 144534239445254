import React, { ReactNode } from 'react'
import ReactJoyride from 'react-joyride'
import { OnboardingTooltip } from '@domoscio/domoscio-ui'
import { User } from '../../contexts/parameters'
import I18n from '../../locales/I18n'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { domoscioAccess } from '../../api/domoscio_access'

type Props = {
  children: ReactNode
}

const steps = () => {
  return [1, 2].map(elem => ({
    title: I18n.t(`tour.adaptive_path.title_step_${elem}`),
    target: `.learning_path_step${elem} button`,
    content: I18n.t(`tour.adaptive_path.step_${elem}`),
    disableBeacon: true
  }))
}

const AdaptivePath = ({ children }: Props) => {
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)

  let runTour = true
  if (user.onboarding?.lxp_hub?.adaptivePath) {
    runTour = false
  }

  const handleCallbackJoyRide = (data: any) => {
    const { action, status } = data

    // Send finished tour to server
    if (status === 'finished' || action === 'reset') {
      const newOnboarding = domUtils.updateTour(user.onboarding, 'lxp_hub', {
        adaptivePath: true
      })
      if (!user.onboarding?.lxp_hub?.adaptivePath) {
        userDispatch({ ...user, onboarding: newOnboarding })
        domoscioAccess.updateUserParameter({ onboarding: newOnboarding })
      } else {
        userDispatch({ ...user, replayTour: { ...user.replayTour, adaptivePath: false } })
      }
    }
  }

  return (
    <div className='tourWrapper'>
      <ReactJoyride
        floaterProps={{ disableAnimation: true }}
        tooltipComponent={OnboardingTooltip}
        steps={steps()}
        continuous={true}
        run={runTour || user.replayTour.adaptivePath}
        styles={{
          options: {
            zIndex: 10000
          },
          spotlight: {
            borderRadius: 15
          }
        }}
        callback={arg => handleCallbackJoyRide(arg)}
      />
      {children}
    </div>
  )
}

export default AdaptivePath
